$simple-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$simple-box-shadow-hover: rgba(0, 0, 0, 0.1) 0px 4px 12px;
$light-box-shadow: rgba(0, 0, 0, 0.13%) 0px 3.2px 7.2px 0px, rgba(0, 0, 0,0.11%) 0px 0.6px 1.8px 0px;
$font-family-primary:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
$shadow-soft:0 4px 12px 0 rgba(0, 0, 0, 0.05);
$shadow-hard: 0 3px 2px -2px rgba(0,0,0,.2);
$navbar-height:50px;
$sidemenu-width:200px;
$input-font-size:1.5rem;
$basic-radius:2px;
$body-line-height:20px;
$title-line-height:30px;


//timeline-post
$dotmenu-width:240px;
$like-button-selected:#150e56; 
$post-space-between:15px;


//colors
$primary-blue:#275efe;
$primary-blue-dark:#013ce9;
$primary-orange:#ff7b54;
$primary-purple:rgb(96, 60, 186);
$primary-grey:rgba(0,0,0,0.54);
$primary-background-grey:rgb(250, 250, 250);
$textfield-color: rgba(0, 0, 0, 0.87);
$placeholder-color: #aaaaaa;
$post-tag-primary:#da9ff9;
$post-tag-secondary:#b088f9;
$post-tag-text-color:white;
$light-blue:rgba(222,236,249,0.5);
$light-blue-darker:rgba(222,236,249,1);
$light-grey:rgba(244,244,244,1);
$standard-text-color:black;
$blue-link-color:rgb(16, 110, 190);

$text-color:rgba(0,0,0,0.9);


//side menu


//functions
@mixin brandName {
    color: $text-color;
    font-weight: 600;
    font-size: 16px;
    line-height: 48px;
    -webkit-font-smoothing: antialiased;
    margin-left: 10px;
}

@mixin centreHorizontally {
    display: table;
    margin: 0 auto;
}

@mixin standardFont {
    color:$textfield-color;
    font-size: $input-font-size;
    font-weight: 400;
    font-family: $font-family-primary;
}

@mixin standardSmallInfoText {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgb(97, 97, 97);
}

@mixin  gradientText {
    /* Create the gradient. */
    background-image: linear-gradient(81.84deg, #0099ff -9.4%, #a033ff 51.57%, #ff5280 84.07%, #ff7061 90.59%);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
}
