@import './variables';


.nav-container{
    background-color: #ffffff;
    height: $navbar-height;
    box-shadow: $shadow-hard;
    z-index: 100;
    display: flex;
    padding: 6px 12px 6px 12px;
    justify-content: space-between;

    position: fixed; /* Make it stick/fixed */
    top: 0; /* Stay on top */
    width: 100%; /* Full width */
    transition: top 0.3s; /* Transition effect when sliding down (and up) */

    .MuiIconButton-root {
        padding: 6px;
    }

    .nav-left{
        display: flex;
        align-items: center;
        
        .logo-container{
            text-decoration: none !important;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: left;

            a:hover{
                text-decoration: none;
            }
            
            .nav-logo{
                width: 30px;
                height: 30px;
            }
            
            #brand-name{
                @include brandName();
            }

            #preview-text{
                font-size: 13px;
                color: black;
                font-weight: 400;
                padding-left: 5px;
            }
        }
    } 

    .nav-right{
        align-items: center;
        align-self: center;
        display: flex;

        #post-btn{
            margin-right: 0px;
            text-transform: none;
            height: $navbar-height;
        }

         #notifications-btn{
            margin-right: 25px;
            text-transform: none;
            height: $navbar-height;
        }
        
        .nav-account-img{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            cursor: pointer;
            transition: all .2s ease-in-out;
            
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}

.navbar-hidden {
  top: calc(-#{$navbar-height});
}


#profile-menu{
    width: 200px;
    align-items: center;
    
    a:hover{
        text-decoration: none;
    }
}

#notifications-container{
    padding: 15px;
    width: 350px;
    background-color: #fff;
    max-height: 500px;
    overflow: auto;

    #empty-notifications{
        display: flex;
        justify-content: center;
        font-size: 14px;
    }

    #title{
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
    }

    hr{
        border-color:  rgba(0,0,0,.08);
    }

    .notifications-item{
        justify-content: space-between;
        display: flex;
        color:$textfield-color;
        font-family: $font-family-primary;
        
        & div:first-of-type{
            display: flex;
        }

        .text{
            padding-left: 15px;
            font-weight: 400;
            font-size: 16px;
            word-break: break-word;
            white-space: break-spaces;
         

            & span:first-of-type{
                font-weight: 500;

                &:hover{
                }
            }
        }

        .time{
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
        }

        .picture{
            width: 45px;
            height: 45px;
            border-radius: 50%;
            cursor: pointer;
        }
    }

}