@import './variables';


.home-main-container{
  display: flex;
  width: 100%;
//   background: red;

    .timeline-scroll{
        @include centreHorizontally();
        // background-color: $light-blue;
        min-width: 50%;

        .empty-timeline{
            @include centreHorizontally();
        }

        .timeline-skeleton{
            min-width: 650px;
            max-width: 650px;
            @include centreHorizontally();
            margin: 0px auto 16px auto;
        }
    }
}
