@import './variables';


.account-container{
    // background-color: aqua;
    width: 80%;

    .heading-wrapper{
        background-color: white;
        border-radius: $basic-radius;
        align-items: center; /* vertical */
        padding: 20px 30px 20px 30px;
        position: relative;
        box-shadow: $shadow-soft;
        min-width: 650px;
    }

    .profile-body{
        display: flex;
        // background-color: antiquewhite;
        margin-top: 30px;

        .details-container{
            width: 350px;
            min-width: 350px;
            max-height: 100%;
            background-color: white;
            box-shadow: $shadow-soft;
            border-radius: $basic-radius;
            padding: 20px;
            color:$textfield-color;

            .detail-title{
                font-size: 16px;
                font-weight: 600;
                line-height: $title-line-height;
            }
            
            .last{
                margin-top: 10px;
            }
            .detail-content{
                font-size: 14px;
                font-weight: 400;
                line-height: $body-line-height;
            }
        }

        .posts-container{
            // background-color: blue;
            margin: auto;
            
            .posts-scroll{
                @include centreHorizontally();
            }
        }
    }
}

 .heading-container{
        background-color: white;
        display: flex;
        align-items: center; /* vertical */

        .picture-wrapper{
            overflow: hidden;
            display: flex;
            align-items: center; /* vertical */
            justify-content: center; /* horizontal */
            width: 150px;
            height: 150px;
            border-radius: 50%;

            .profile-picture{
                width: 100%;
            }
        }

        .profile-titles{
            padding-left: 30px;
            padding-top: 20px;
            @include standardFont();

            .primary{
                font-weight: 600;
                line-height: 40px;
            }

            .secondary{
                font-weight: 400;
                font-size: medium;
                line-height: 25px;
            }

            .profile-stats-container{
                display: flex;
                // background-color: antiquewhite;
                // margin-left: 150px;
                margin-top: 20px;

                .stats{
                    margin: 0 20px 0 0;
                    text-align: center;

                    .stats-figure{
                        color:$textfield-color;
                        font-weight: 600;
                        font-size: large;
                    }

                    .stats-name{
                        color: $primary-grey;
                        font-weight: 400;
                        font-size: small;
                    }
                }

                .vertical-line{
                    border-left: 1px solid rgba(0,0,0,0.12);
                    margin-right: 20px;
                }
            }
        }

        .right-float{
            position: absolute;
            right: 30px;

            .MuiButton-contained {
                background-color: $primary-blue;
            }
        }
}

