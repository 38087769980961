@import './variables';


.search-wrapper{
    position: relative;
    width: 100%;
    margin-left: 50px;

    .search-input{
        box-shadow: 0 2px 4px -0.75px rgba(0, 0, 0, 0.10);
    }

    .MuiInputBase-input{
        background: $light-blue-darker;
        color: $standard-text-color;
        border-radius: 4px;
        width: 350px !important;
        font-size: 16px;

        &:focus{
            background: $light-blue;
        }
    }

    .search-icon{
        padding: 10px;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color:black;
        z-index: 200;

        svg{
            width: 20px;
        }
    }
}

.live-dropdown-container{
    z-index: 1000;
    position: fixed !important;
    top: $navbar-height !important;
    left: 260px !important;
    transform: none !important;
    will-change: inherit !important;

    .live-content{
        width: 400px;
        border-radius: 5px;
        background-color: white;
    }
}

.live-search-card-container{
    padding: 8px;
    padding-left: 15px;
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;

    .message{
        @include centreHorizontally();
        font-size: 16px;
        font-weight: 400;
    }

    &:hover{
        background-color: rgba(0, 0, 0, 0.06);
    }

    .user-profile-picture{
        overflow: hidden;
        display: flex;
        align-items: center; /* vertical */
        justify-content: center; /* horizontal */
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .profile-titles{
        padding-left: 15px;
        @include standardFont();
        align-self: center;

        .name{
            color:$textfield-color;
            font-size: 16px;
            font-weight: 500;
        }
        
        .title{
            color: $primary-grey;
            font-weight: 400;
            font-size: 14px;
        }
    }
}