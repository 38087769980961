@import '../variables';

 .nbutton{
    --font-size: 16px;
    --duration: .2s;
    --shadow: 0 2px 8px -1px #{rgba($primary-blue, .32)};
    --shadow-hover: 0 4px 15px -2px #{rgba($primary-blue, .5)};
    --font-shadow: var(--font-size);

    padding: 13px 22px;
    font-weight: 500;
    line-height: 1;
    border-radius: 2px;
    outline: none;
    text-decoration: none;
    font-size: var(--font-size);
    letter-spacing: .5px;
    background: $primary-blue;
    color: white;
    box-shadow: var(--shadow);
    transition: transform var(--duration) ease, box-shadow var(--duration) ease;
    cursor: pointer;
    border: 0;
    white-space: nowrap;
    border: 1px solid $primary-blue;
                
    &:hover {
        background: $primary-blue-dark;
        --shadow: var(--shadow-hover);
    }

    &.secondary, &.icon.primary {
        background: #fff;
        color: $primary-blue;
        --shadow: 0 2px 8px -1px #{rgba(#121621, .04)};
        --shadow-hover: 0 4px 20px -2px #{rgba(#121621, .12)};

        &:hover{
            background: $light-blue;
        }
    }

    &.small{
        padding: 10px 14px;
    }

    &:disabled{
        background: rgba(0, 0, 0, 0.12);
        border: 1px solid rgba(0, 0, 0, 0.12);
        color: rgba(0,0,0,0.4);
        --shadow: 0 2px 8px -1px #{rgba(#121621, .04)};
        --shadow-hover: 0 4px 20px -2px #{rgba(#121621, .12)};
    }

    &.icon{
        padding: 7px 13px;
        border: none;

        svg{
            font-size: 20px;
        }
    }

    .button-content{
        display: flex;
        align-items: center;

        & div:first-of-type{
            padding-left: 5px;
        }
    }
}