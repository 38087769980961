:root{
    --read-font-color:rgba(41, 41, 41, 1);
    --read-font-color-grey:rgba(117, 117, 117, 1);
    --read-font:medium-content-title-font, Georgia, Cambria, "Times New Roman", Times, serif;
    --general-font: 'Sailec-Bold','system-ui','-apple-system','BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Helvetica','Arial','sans-serif';
    --general-font-color:rgba(41, 41, 41, 1);
    --icons-fill-color:rgba(117, 117, 117, 1);
    --icons-fill-hover:rgba(41, 41, 41, 1);

}

.read-container{
    width: 100%;
    background-color: white;
}

.read-body{
    max-width: 680px;
    margin: 0 auto;
    display: table;
    margin-bottom: 40px;
}

.stats-container{
    display: flex;
    position: absolute;
    right: 0;
}

.stats-icon{
    padding: 10px;
    display: flex;
    cursor: pointer;
}

.stats-icon svg{
    fill: var(--icons-fill-color);
}

.stats-icon:hover{
    .stats-p{
        color:var(--icons-fill-hover);
    }
    svg{
        fill: var(--icons-fill-hover);
    }
}

.stats-p{
    margin:0;
    font-family: var(--general-font);
    font-size: 14px;
    color:var(--read-font-color-grey);
    padding-left: 5px;
}

.general-h1{
    color: var(--general-font-color);
    font-size: 36px;
    line-height: 110%;
    font-weight: normal;
    letter-spacing: -1px;
    word-break: break-word;
    font-family: var(--general-font);

}

.general-h2{
    font-size: 18px;
    line-height: 120%;
    font-family: var(--general-font);
    color: var(--general-font-color);
    letter-spacing: 0;
    margin-top: 15px;
    margin-bottom: 10px;
    word-break: break-word;
}

.general-p{
    font-size: 20px;
    line-height: 150%;
    color: var(--general-font-color);
    font-family: var(--general-font);
    margin-bottom: 0;
    margin-top: 20px;
    font-weight: 400;
    word-break: break-word;
    font-style: normal;
}

.read-h1{
    line-height: 48px;
    margin-top: 0.78em;
    font-size: 40px;
    margin-bottom: -0.27em;
    font-weight: 400;
    font-family: var(--read-font)
}

.read-h2{
    font-weight: 600;
    color: var(--read-font-color);
    letter-spacing: -0.022em;
    font-size: 26px;
    margin-top: 1.3em;
    margin-bottom: -0.31em;
    font-family: var(--read-font)
}

.read-h2.subtitle{
    color: var(--read-font-color-grey);
    line-height: 32px;
    margin-top: 0.79em;
    font-size: 22px;
    margin-bottom: -0.42em;
    font-weight: 300;
}

.read-p{
    font-weight: 400;
    color: var(--read-font-color);
    word-break: break-word;
    margin-top: 0.86em;
    line-height: 32px;
    font-size: 21px;
    margin-bottom: -0.46em;
    font-family: var(--read-font)
}

.author-container{
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    position: relative;
}

.read-authors-image{
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
}

#read-author-name, #read-date{
    margin:0px;
    font-size: 14px;
    padding-left: 15px;
}

#read-author-name{
    color: var(--read-font-color);
    cursor: pointer;
}

#read-date{
    color: var(--read-font-color-grey);
}

#read-text{
    margin-bottom: 40px;
}

#read-tags-container{
    margin-top: 25px;
    display: block;
}

.read-tags-list{
    list-style: none;
    list-style-image: none;
    padding:0;
    margin:0;
}

.read-tag-li{
    margin-right: 8px;
    margin-bottom: 15px;
    display: inline-block;
}

.read-tag-a{
    color: rgba(117, 117, 117, 1);
    font-size: 15px;
    text-decoration: none;
    padding: 5px 10px;
    background: rgba(242, 242, 242, 1);
    border-radius: 3px;
    line-height: 22px;
    font-weight: 400;
}

.wallpaper-img{
    width: 100%;
}

.wallpaper-reference{
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 300;
    color:var(--read-font-color-grey)
}

.wallpaper-container{
    margin-bottom: 60px;
    margin-top: 25px;
}