@import './variables';

.comments-container{

    .show-more{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        margin-bottom: 10px;
        color: $blue-link-color;

        &:hover{
            text-decoration: underline;
        }
    }

    hr{
        margin-bottom: $post-space-between;
    }

    .reply-wrapper{
        @include standardSmallInfoText();
        margin-bottom: 10px;
        padding-left: 26px;
        // background-color: antiquewhite;
        display: flex;
        align-items: center;
        // justify-content: space-between;

        .cancel-reply{
            cursor: pointer;
            margin-right: 10px;

            &:hover{
                background-color: #f4f9f9; //f4f9f9, f9f7f7, f6f6f6
            }

            svg{
                display: block;
                font-size: 20px;
            }
        }


        .info{
            color: rgb(97, 97, 97);
        }

        .name{
            padding-left: 5px;
            font-weight: 700;
            color: rgb(16, 16, 16);
            white-space: nowrap;
        }
    }

    .user-comment{
        display:  flex;
        
        .profile-pic{
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }

        .textfield-wrapper{
            margin-left: 20px;
            width: 100%;
            @include standardFont();
            line-height: 1.1876em;
            letter-spacing: 0.00938em;

            .MuiInput-underline:before {
                border: none;
            }

            .MuiInput-underline:after {
                border: none;
            }
        }
    }
}

@keyframes fade-in{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes roll-in{
    0% {
        transform: translate3d(0px, -10px, 0px);
        pointer-events: none;
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
        pointer-events: auto;
    }
}

.comment-item{
    display: flex;
    padding-bottom:18px;
    position: relative;
    max-width: 90%;

    .profile-pic{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        cursor: pointer;
    }

    .body-wrapper{
        margin-left: 10px;
        background-color: #f4f9f9; //f4f9f9, f9f7f7, f6f6f6
        font-size: 14px;
        border-radius: 0px 15px;
        padding: 6px;
        display: table;
        position: relative;

        .name-wrapper{
            font-weight: 700;
            padding-bottom: 0px;
            color: rgb(16, 16, 16);
            white-space: nowrap;
            display: flex;
            text-align: center;
            justify-content: space-between;
            align-items: center;

            .name{
                display: flex;
            }

            .comment-dot{
                color:rgba(0, 0, 0, 0.54);
                margin-left: 20px;
                font-size:  20px;
                cursor: pointer;

                &:hover{
                    background-color: #e2e5e5;
                }
            }

            .user{
                color: rgb(16, 16, 16);
                cursor: pointer;
                text-overflow: ellipsis;
                max-width: 200px;
                overflow: hidden;
                display: inline-block;

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        .text{
            padding-bottom: 5px;
            font-weight: 400;
        }

        .date{
            @include standardSmallInfoText;
            margin-left:10px;
            line-height: 20px;
        }

        .reply-to{
            @include standardSmallInfoText;
            margin-left: 10px;
            margin-right: 10px;
            line-height: 20px;
        }
    }

    .buttons{   
        font-weight: 500;
        font-size: 13px;
        display: flex;
        margin-left: 20px;
        margin-top: 5px;
        align-items: center;

        .like-btn, .reply-btn{
            cursor: pointer;
        }

        .like-btn.filled{
            background-color: #f4f9f9;
            border-radius: 2px;
        }

        .like-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;

            .like-icon{
                padding-left: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 50px;
                color: $like-button-selected !important;

                svg{
                    display: block;
                    font-size: 18px;
                }
            }
        }

        .verticalLine {
            border-left: 1px solid rgba(0, 0, 0, 0.2);
            margin-left: 5px;
            margin-right: 5px;
            height: 13px;
        }
    }
}