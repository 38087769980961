@import '../variables';

.cancel-icon{
    cursor: pointer;

    &:hover{
        background-color: rgba(0, 0, 0, 0.04); //f4f9f9, f9f7f7, f6f6f6
    }

    svg{
        color: rgba(0, 0, 0, 0.8);
        display: block;
        font-size: 23px;
    }
}