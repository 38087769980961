@import './variables';


.search-results-container{
    background-color: white;
    border-radius: $basic-radius;
    box-shadow: $shadow-soft;
    @include centreHorizontally();

     hr{
        margin: 0;
    }

    .pagination-container{
        padding: 20px;
        display: flex;
        position: relative;
        @include centreHorizontally();
    }
}

.user-search-card-container{
    display: flex;
    position: relative;
    width: 650px;
    background-color: white;    
    width: 650px;
    padding: 24px;
    transition: box-shadow 0.1s ease 0s;

    .error{
        @include centreHorizontally();
    }

    &:hover{
        background-color: rgba(0, 0, 0, 0.02);
    }

    .user-profile-picture{
        overflow: hidden;
        display: flex;
        align-items: center; /* vertical */
        justify-content: center; /* horizontal */
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
    }

    .profile-titles{
        padding-left: 30px;
        padding-top: 10px;
        @include standardFont();

        .name{
            cursor: pointer;
            color:$textfield-color;
            font-size: large;
            font-weight: 600;
        }

        .title{
            color: $primary-grey;
            font-size: medium;
            font-weight: 500;
        }
    }

    .right-float{
        position: absolute;
        right: 20px;

        button{
            background-color: rgba(0, 0, 0, 0.02);
            font-size: 30px !important;

            &:hover{
                background-color: rgba(0, 0, 0, 0.08);
            }
        }
    }
}