@import './variables';


.MuiPaper-rounded {
    border-radius: $basic-radius !important;
}

.MuiDialog-paper{
    padding: 20px;
}

.MuiDialogContent-root{
    padding: 20px 0px 20px 0px !important;
}

.dialog-header{
    width: 100%;
    position: relative;
  
    display: flex;
    justify-content: center;
    align-items: center;

    .dialog-title{
        color:$textfield-color;
        font-size: 18px;
        font-weight: 600;
        line-height: $title-line-height;
    }

    .dialog-cancel{
        position: absolute;
        right: 0px;
        top: 0px;
    }
}

.dialog-footer{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
    
    .nbutton:first-child{
        margin-right: 10px;
    }

    &.space-between{
        justify-content: space-between;
    }
}


.dialog-body{
    width: 500px;
    @include centreHorizontally();

    img{
        @include centreHorizontally();
    }
    
   .MuiTextField-root{
       margin-bottom: 30px;
   }

   .MuiTextField-root:last-child{
       margin-bottom: 10px;
   }
}


//-------------Edit Profile----------------
 .edit-profile-picture{
    // background-color: antiquewhite;
    position: relative;
    height: 300px;
    margin-bottom: 25px;
    justify-content: center;
    align-items: center;
    display: flex;

    .picture-wrapper{
        background-color: black;
    }

    .no-image{
        button{
            margin-top: 20px;
        }

        div{
            @include centreHorizontally();
        }
    }

    .edit-button-wrapper{
        position: absolute;
        right: 10px;
        bottom: 10px;

        .MuiIconButton-root {
            padding: 6px;
            border-radius: 50%;
            // background-color: $primary-background-grey;
            background: white;
            color: $primary-blue;
            box-shadow: $simple-box-shadow;
        }
    }

    .reactEasyCrop_CropArea{
        box-shadow: 0px 0px 0px 200px rgba(0,0,0,0.8);
    }
}


//-------------Create-Update Post----------------
.post-body{
    --font-size:20px;

    .MuiInputBase-root {
        font-size: var(--font-size);
    }

    .MuiInputBase-input {
        min-height: 120px;
    }

    ::placeholder{
        font-size: var(--font-size);
        color: red;
   }

    .post-body-field{
        .MuiInput-underline:before {
            border: none !important;
        }

        .MuiInputBase-input {
            font-size: var(--font-size);

            &::placeholder{
                font-size: var(--font-size);
            }
        }

        .MuiInput-underline:after {
            border: none !important;
        }
    }

    .react-tag-input {
        @include standardFont();
        border: none;
        font-size: 16px;

        .react-tag-input__input {

            &::placeholder{
               font-size: 16px;
                color: $placeholder-color;
                line-height: normal;
            }
        }

        // .react-tag-input__tag {
        //     background: $post-tag-primary;
        //     color: $post-tag-text-color;

        //     .react-tag-input__tag__remove{
        //         background: $post-tag-secondary;

        //         &::after, &::before{
        //             background-color: white;
        //         }
        //     }
        // }
    }

    .image-preview-container{
        margin-bottom: 30px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .cancel-icon{
            right: 10px;
            top: 10px;
            z-index: 100;
            position: absolute;

            svg{
                font-size: 30px;
            }

            &:hover{
                background-color: rgba(#f4f9f9, 0.5); //f4f9f9, f9f7f7, f6f6f6
            }
        }

        .image-preview{
            max-width: 450px;
            border-radius: 2px;
            -webkit-filter: brightness(100%);

            &:hover {
                -webkit-filter: brightness(80%);
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
        }
    }
}

//-----------------Report Abuse----------------
#report-abuse-info{
    margin-bottom: 10px;
}