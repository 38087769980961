@import './variables';


.timeline-post-container{
    background-color: white;
    box-shadow: $simple-box-shadow;
    min-width: 650px;
    max-width: 650px;
    border-radius: 2px;
    @include centreHorizontally();
    padding: 24px;
    margin-bottom: 24px;
    position: relative;
    transition: box-shadow 0.1s ease 0s;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-word;

    &:hover{
        box-shadow: $simple-box-shadow-hover;
    }

    .post-img-container{
        background-color: black;
        margin-bottom: 5px;
        cursor: pointer;

        .post-img{
            @include centreHorizontally();
            max-width:100%;
            max-height:400px ;
        }
    }

    .post-title-container{
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 20px;
        color: rgba(0,0,0,0.9);
    }

    .post-bodytext-container{
        font-weight: normal;
        font-size: medium;
        color: rgba(0,0,0,0.9);
        word-break: break-word;
        margin-bottom: 15px;
        white-space: break-spaces;

        .post-read-more{
            cursor: pointer;
            font-weight: 600;

            &:hover{
                text-decoration: underline;
            }
        }
    }

    hr{
        margin: 0;
    }

    .post-tags-container{
        margin-top: 30px;

        .post-tag{
            margin-right: 10px;
            cursor: pointer;
            color: rgba(117, 117, 117, 1);
            font-size: 15px;
            text-decoration: none;
            padding: 5px 10px;
            background: rgba(242, 242, 242, 0.5);
            border-radius: 3px;
            line-height: 22px;
            font-weight: 400;

            &:hover{
                background: rgba(242, 242, 242, 1);
            }
        }
    }

    .post-body{
        padding-bottom: $post-space-between;
    }

    .post-buttons{
        padding-bottom: $post-space-between;
    }

    .post-buttons-container{
        display: flex;
        justify-content: flex-start;
        padding-bottom: 10px;

        .likes-container{
            margin-right: 10px;
        }

        .MuiButton-root {
            transition: none !important;
        }

        .button-filled{
            color: $like-button-selected !important;

            .MuiButton-label {
                color: $like-button-selected !important;
            }
        }

        .MuiButton-label {
            // color: #172b4d;
            color: rgba(0,0,0,0.9);
        }
    }

}

.post-heading{
    width: 100%;
    display: flex;
    position: relative;
    padding-bottom: $post-space-between;

    .post-profile-pic{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        cursor: pointer;
    }

    .post-author{
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;

        .author-name{
            color:$textfield-color;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
        }

        .post-date{
           @include standardSmallInfoText
        }

        .post-edited{
           @include standardSmallInfoText
            margin-left: 8px;

            .bulletpoint{
                margin-right: 8px;
            }
        }
    }

    .dot-menu{
        position: absolute;
        right: 0;

        .dotmenu-item{
            display: flex;

            span{
                margin-left: 15px;
                color: #1c1e21;
            }
        }

        #popper{
            width: $dotmenu-width;
            left: calc(330% - #{$dotmenu-width}) !important;
            z-index: 200 !important;
        }

        .MuiIconButton-root{
            border-radius: 0%;
            padding: 6px;
        }
    }

}
