@import './variables';

.simple-popover{
    background-color: white;
    box-shadow: $light-box-shadow !important;
    z-index: 1000;

    .paper-transition{
        transition:opacity 243ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 162ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
        animation-name: fade-in, roll-in;
        animation-duration: 0.3s;
        animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
        animation-fill-mode: both;
    }
 
    .item{
        white-space: nowrap;
        display: flex;
        align-items: center;
        max-width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: rgb(16, 16, 16);
        background-color: transparent;
        border: none;
        width: 100%;
        line-height: 36px;
        cursor: pointer;
        padding: 0px 10px 0px 10px;
        text-align: left;

        &:hover{
            background-color: rgb(242, 242, 242);
            color: rgb(16, 16, 16);
        }

        .text{
            margin: 0px 4px;
            display: inline-block;
            flex-grow: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            -webkit-font-smoothing: antialiased;
            width: 100%;
            padding: 0px 8px 0px 4px;
            text-align: left;
        }
    }
}
